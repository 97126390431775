<template lang="pug">

div(fluid)
  
  v-container.justify-center.aling-center(style="margin-bottom: 3em; margin-top: 3em")
    v-row.justify-center.aling-center
      v-col( cols=12 md=5 )
        bloque_arbitros( :titulo='adjudicadores_registro_de_árbitros.titulos.adjudicadores_dispute_boards' :arreglo='adjudicadores_registro_de_árbitros.adjudicadores_dispute_boards').mt-10
     

</template>
<script>
export default {
  components: {
    bloque_arbitros: ()=>import('./bloque_arbitros')
  }
}
</script>



